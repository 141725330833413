import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { PortableText } from '@portabletext/react'
import { Helmet } from 'react-helmet'
import { Parser } from 'html-to-react'
// import Breadcrumb from '../components/Breadcrumb'

const ptComponents = {
  types: {
    dangerouslySetInnerHTML: ({ value }) => <div dangerouslySetInnerHTML={{__html: value.html}} />
  }
}

const PromoPage = ({ data }) => {
  const {
    title,
    featuredImage,
    _rawContent,
    customCode
  } = data.sanityPromoPage

  // Parser for custom code html scripts
  const { parse } = new Parser()

  return (
    <StyledPromoPage>
      {customCode?.documentHead && (
        <Helmet>{parse(customCode.documentHead)}</Helmet>
      )}

      <h1>{title}</h1>
      <p className="breadcrumbs">
        <Link to='/'>Home</Link> {`>`}&nbsp;
        {title}
      </p>
      {/* <Breadcrumb crumbItems={[{title: 'Test', slug: '/test/'} , { title }]} /> */}

      <GatsbyImage image={getImage(featuredImage.asset)} alt={title} />
      <PortableText 
        value={_rawContent} 
        components={ptComponents}
      />

      {/* --- Body Script --- */}
      {customCode?.beforeClosingBodyTag && parse(customCode.beforeClosingBodyTag)}
    </StyledPromoPage>
  )
}

export const query = graphql`
  query PromoPageQuery($slug__current: String) {
    sanityPromoPage(slug: {current: {eq: $slug__current}}) {
      title
      featuredImage {
        asset { gatsbyImageData(layout: FULL_WIDTH) }
      }
      _rawContent
      customCode {
        documentHead
        beforeClosingBodyTag
      }
    }
  }
`

const StyledPromoPage = styled.main`
  max-width: 1200px;
  margin: auto;
  padding: 4rem 2rem;

  h1 {
    margin-bottom: 0.5rem;
    font-size: 3rem;
  }

  @media (max-width: 480px) {
    padding: 2rem 1rem;

    h1 {
      font-size: 2rem;
    }
  }
`

export default PromoPage